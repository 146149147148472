.footer {
  height: auto;
  display: flex;
  /* margin-top: 20%; */
  /* flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
  background-image: url(./../../resources/images/footer.png);
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
  color: white;
  /* padding: 100px 38%; */
}
.powered {
  color: white;
  text-decoration: none;
}

.content-footer {
  margin-top: 10%;
  width: 100%;
  padding: 5%;
}
.list-footer {
  float: right;
  display: flex;
  flex-direction: column;
  list-style: none;
}
.list-footer a {
  color: white;
  text-decoration: none;
}
.social-container {
  width: 100%;
  padding: 20px 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}
.social-container a {
  padding-inline-end: 80px;
}
.title-list {
  font-size: 1.3rem !important;
  margin-bottom: 10px;
}
.list-footer li {
  font-size: 1.2rem;
  padding-bottom: 10px;
}
.footer .fa {
  color: white;
  font-size: 2.5em;
}
@media (min-width: 1281px) {
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .title-list {
    font-size: 1.2rem !important;
  }
  .list-footer li,
  .footer-copy {
    font-size: 1.1rem;
  }

  .content-footer {
    margin-top: 12%;
  }
  .content-footer img {
    width: 93%;
  }
  .content-footer i {
    font-size: 1.7em !important;
  }
  .social-container a {
    padding-inline-end: 60px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .title-list {
    font-size: 1.1rem !important;
  }
  .list-footer li,
  .footer-copy {
    font-size: 1rem;
  }
  .content-footer {
    margin-top: 18%;
  }
  .content-footer img {
    width: 85%;
  }
  .content-footer i {
    font-size: 1.5em !important;
  }
  .social-container a {
    padding-inline-end: 50px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .title-list {
    font-size: 1rem !important;
  }
  .list-footer li,
  .footer-copy {
    font-size: 0.9rem;
  }
  .content-footer {
    margin-top: 18%;
  }
  .content-footer img {
    width: 75%;
  }
  .content-footer i {
    font-size: 1.3em !important;
  }
  .social-container a {
    padding-inline-end: 40px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .title-list {
    font-size: 0.9rem !important;
  }
  .list-footer li,
  .footer-copy {
    font-size: 0.8rem;
  }
  .content-footer {
    margin-top: 22%;
  }
  .content-footer img {
    width: 75%;
  }
  .content-footer i {
    font-size: 1.2em !important;
  }
  .social-container a {
    padding-inline-end: 30px;
  }
}

/* 
    ## 320PX - 480PX
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 320PX to 480PX
    */
@media (min-width: 320px) and (max-width: 575px) {
  .title-list {
    font-size: 0.8rem !important;
  }
  .list-footer li,
  .footer-copy {
    font-size: 0.7rem;
  }
  .content-footer {
    margin-top: 28%;
  }
  .content-footer img {
    width: 70%;
  }
  .content-footer i {
    font-size: 0.9em !important;
  }
  .footer {
    justify-content: space-around;
  }
  .social-container a {
    padding-inline-end: 20px;
  }
  .footer .fa {
    color: white;
    font-size: 2em;
    margin: 0 0.5em;
  }
}
