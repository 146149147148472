.app-header {
  height: auto;
  background-image:url(./../../resources/images/Path-25w.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}
p
{
    display: block;
}
.MuiFab-extended {
/*   width: auto!important; */
  height: 5% !important;
  padding: 0 16px!important;
  min-width: 5%!important;
  min-height: auto!important;
  border-radius: 44px!important;
  padding: 3% !important;
}

.MuiFab-root{
  font-size: 20px !important;
  font-weight: bold !important;
}
.register-btn{
  background: white !important;
  color: #F68D1E !important;

}
.enviamelo-mobile{
  position: absolute;
/*   max-width: 100%;
  top: -10px;
  left: -200px; */
}
.enviamelo-mobile p{
  color: #F68D1E;
  text-align: center;
 /*  padding-left: 15%; */
  margin-top: -2%;
}
.enviamelo-mobile a{
  color: inherit;
  text-decoration: inherit;

}
.header-description{
  font-size: 30px;
}

.header-curve-img {
  position: absolute;
  top: 60%;
  width: 100%;
  z-index: 1;
}

.header-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 4em;
  /*padding: 4em 8em 0em 1em;*/
}


.header-content .left-content {
  width: 50%;
}

.lc-enterprise {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  line-height: 3em;
  color: #0066ff !important;
}
.lc-enterprise span {
  font-size: 38px;
}
.left-content .mobile-logo,
.left-content .register-btn-mobile {
  display: none;
}

.left-content {
  text-align: center;
  flex-wrap: wrap;
}

.left-content > span {
  font-size: 48px;
}

.left-content > p {
  font-size: 18px;
}

.stores-img a {
  display: inline-block;
  margin-right: 15px;
  z-index: 5;
}
.stores-img a img {
  max-width: 130px;
}

.right-content {
  position: relative;
  width: 50%;
 /*  padding-top: 10%; */
/*   width: 58%; */
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.isologotipo {
  position: relative;
  top: -25px;
  left: -5px;
  width: 120px;
}

.right-content > img {
  position: relative;
  max-width: 880px;
  z-index: 10;
  bottom: 0;
}

.btn-campain-mobile {
  display: none;
}

.align-center {
  text-align: center;
}

.span-campaign {
  display: block;
}
.btn-campain-mobile .btn-campain-desktop {
  white-space: nowrap;
}

/* 
  ## > 1281PX
  ##Tv - Extra Large devices
  ##Screen = B/w > 1281px
  */
  .enviamelo-mobile {
    bottom: -40%;
  }
  @media(min-width: 1750px){
    .enviamelo-mobile {
      bottom: -80%;
    }
    .app-header {
      padding-bottom: 4vw;
      background-position: 0 -170px !important;
    }
  }
@media(min-width: 1500px) and (max-width: 1749px){
  .app-header {
    padding-bottom: 4vw;
    background-position: 0 -20px !important;
  }
  .enviamelo-mobile {
    bottom: -60%;
  }
}
@media(min-width: 1281px) and (max-width: 1499px) {
  .enviamelo-mobile {
    bottom: -50%;
  }
  .app-header {
    padding-bottom: 4vw;

  }
  .header-content .left-content {
    display: flex;
    align-items: center;

  }
  .MuiContainer-fixed {
    max-width: 1499 !important;
  }
}
/* 
  ## 1025PX - 1280PX
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
  .enviamelo-mobile {
    bottom: -50%;
  }
  .enviamelo-mobile img{
/*     width: 560px !important; */
  }
  .enviamelo-mobile p{
   /*  position: absolute; */
/*     right: -350px; */
  }
  .left-content > span {
    font-size: 35px;
  }
  .header-description {
    font-size: 20px;
  }
  .app-header {
    padding-bottom: 3vw;
  }
  .header-content .left-content {
    display: flex;
    align-items: center;
  
  }
}

/* 
## 768PX - 1024PX
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
 
  .enviamelo-mobile {
    bottom: -40%;
  }
  .enviamelo-mobile p{

    font-weight: bold;
  }
  .enviamelo-mobile img{
   /*  width: 300%; */
  }
  .header-content .left-content {
    display: flex;
    align-items: center;

  }
  .left-content > p {
    font-size: 14px;
  }
  .left-content > span {
    font-size: 30px;
  }
  
  .header-description{
    font-size: 18px;
  }

  .enviamelo-mobile > img {
/*     max-width: 480px !important; */
  }

}

/* 
    ## 768PX - 1024PX
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
    */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {


  .header-content .left-content {
    display: flex;
    align-items: center;

  }
  .left-content > span {
    font-size: 30px;
  }
  .header-description{
    font-size: 18px;
  }
  .left-content > p {
    font-size: 12px;
  }

  .stores-img a img {
    max-width: 75px;
  }
  .right-content > img {
    max-width: 480px;
  }
  .MuiFab-extended{
    font-size: 18px !important;
  }
}

/* 
## 481 - 767PX
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/
@media (min-width: 576px) and (max-width: 767px) {
  /* Home */

  .app-header {
    padding-bottom: 6vw;

  }


  .left-content .mobile-logo,
  .left-content .register-btn-mobile {
    display: block;
    width: 100px;
  }

  .register-btn-mobile {
    margin-top: 14px;
  }
  .left-content > span {
    font-size: 24px;
  }
  .header-description{
    font-size: 14px;
  }
  .MuiFab-extended{
    font-size: 14px !important;
  }
  .left-content > p {
    display: none;
  }
  .header-content .left-content {
    width: 100%;
  }
  .right-content {
    width: 66%;
  }
  .stores-img a img {
    display: none;
  }
  .right-content > img {
    max-width: calc(380px + 10vw);
  }
  .header-content {
    overflow: hidden;
    padding: 0;
  }
}
/*
## 320PX - 480PX
##Device = Tablets, Ipads (landscape)
##Screen = B/w 320PX to 480PX
*/
@media (min-width: 320px) and (max-width: 575px) {
  /* Home */
  .left-content .mobile-logo,
  .left-content .register-btn-mobile {
    display: block;
    width: 80px;
  }

  .register-btn-mobile {
    margin-top: 10px;
  }
  .left-content > span {
    font-size: 20px;
  }
  .header-description{
    font-size: 12px;
  }
  .left-content > p {
    display: none;
  }
  .header-content .left-content {
    width: 100%;
  }
  .right-content {
    width: 66%;
  }
  .stores-img a img {
    display: none;
  }
  .right-content > img {
    max-width: calc(250px + 10vw);
  }
  .header-content {
    overflow: hidden;
    padding: 0;
  }

  .MuiFab-extended{
    font-size: 10px !important;
  }
}

@media (min-width: 1280px){

  .MuiContainer-maxWidthLg {
      max-width: 1800px !important;
  }
}
@media (min-width: 960px) and (max-width: 1279px){
  .MuiContainer-fixed {
    max-width: 1100px !important;
}
}

@media (min-width: 600px) and (max-width: 959px){
.MuiContainer-fixed {
    max-width: 800px !important;
}
}


