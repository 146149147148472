


 #menu ul a {
  padding:15px;
  color: white;
 }

#menu ul ul {
  /** display:none; **/
  display:none;
  position:absolute;
 }
 
 #menu ul li:hover > ul {
  display:block;
  padding:15px;
 }


 #menu ul {
  list-style-type: none;
  margin-left: -10px;
  padding: 0;
}

#menu li.nav {
  background: white !important;
  width: auto;
  height: 43px;
  padding: 8px 20px;
  min-width: 48px;
  min-height: auto;
  border-radius: 24px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  margin-top: 12px;

}
#menu li.nav a {
  color: #F68D1E !important;


  display: inline-flex;
  outline: none;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

}








.MuiPaper-root {
  padding: 5.5%;
}
.demo-btn{
  width: 50% !important;
  background-image: linear-gradient(to right, #F68D1E 21%, #F05822 100%) !important;
  color: white !important;

}
.app-pc{
  display: table-cell;
}
.app-display-text{
  display: -webkit-box;
}
.menu-ws{
  padding: 20px;
}
.MuiListItem-button:hover {
  text-decoration: none;
   background-color:unset !important;
}
.MuiFab-primary {
  background-color: #51CA61 !important;
}
.ws-button .MuiFab-label{
  font-size: 15px;
  padding-right: 15px;
  padding-left: 15px; 
}
.whatsapp {
  position: fixed !important  ;
    bottom: 5%;
    left: 5%;
}
.demo-btn .MuiFab-label{
  position: absolute !important;
}
.paper-long {
  padding: 50px !important;
}
.MuiPaper-rounded {
  border-radius: 20px !important;
}
.no-paper {
  margin-top: 90px !important;
}
.first-grid{
  margin-top: -5% !important;
}
.grid-enviamelo {
  background-image: url(./../../resources/images/grid-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.img-left {
  float: left;

  margin-right: 10%;
}
.app-container-enviamelo{
  padding-left: 10% !important;
  padding-right: 10% !important;
}
.MuiGrid-root {
  margin-bottom: 3% !important;
}

.MuiPaper-root img,
.MuiGrid-item img {
  max-width: 13%;
}
.margin-end{
  margin-block-end: 1.3em;
}
.margin-start{
  margin-block-start: 1em;

}
.paper-long img {
  max-width: 10% !important;
}

.paper-middle img {
  max-width: 28% !important;
}

.why-enviamelo {
  position: relative;
}

.logistica-integral {
  position: relative;
}

.why-enviamelo-title {
  position: absolute;
  top: 35%;

}
.row {
  margin-right: 0;
  margin-left: 0  /* margin-left: 0px !important; */;

}


.logistica-integral-title {
  position: absolute;
  top: 120%;
  left: 0%;
}

.inline-img {
  margin-left: 10%;
  margin-bottom: 40%;
}

.inline-p {
  margin-block-start: 0;
    margin-block-end: 1em;
    display: -webkit-box;  /* display: table; */
}

.inline-p span {
  font-weight: bold;
  margin-bottom: 10px;
}



.grid-footer {
  margin-top: 10% !important;
}

.MuiGrid-item {
  padding-right: 50px;
}

.registrate-title {
  text-align: center;
  line-height: 65px;
}

.img-center {
  text-align: center;
}
@media(min-width: 1750px){
  .fist-box{
    height: 140px;
  }
  .last-box{
 
    height: 260px;
}
  }
  @media(min-width: 1500px) and (max-width: 1749px){
    .last-box{
 
      height: 270px;
  }
  }
  @media(min-width: 1281px) and (max-width: 1499px) {
    .last-box{
 
      height: 280px;
  }
  }
@media (min-width: 1025px) and (max-width: 1280px) {
  .MuiPaper-root img,
  .MuiGrid-item img {
    max-width: 19%;
  }
  .paper-long img {
    max-width: 10% !important;
  }
  .paper-middle img {
    max-width: 26% !important;
  }
  .grid-enviamelo {
    background-position: center;
  }
  .last-box{
 
    height: 300px;
}
.fist-box{
  height: 200px;
}
}
/*
  ## 768PX - 1024PX
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
  */
@media (min-width: 768px) and (max-width: 1024px)  {
  .MuiPaper-root img {
    max-width: 30%;
  }

  .MuiGrid-item img {
    max-width: 22%;
  }

  .paper-long img {
    max-width: 12% !important;
  }

  .logistica-integral-title {
    top: 145%;
  }

  .paper-middle img {
    max-width: 32% !important;
  }

  .registrate-title {
    line-height: 45px;
  }

  .grid-enviamelo {
    background-size: auto;
    background-position: center;
  }
  .last-box{
 
    height: 350px;
}
.fist-box{
  margin-top: 70px;
  height: 150px;
}
}

/* 
  ## 481 - 767PX
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
  */
@media (min-width: 576px) and (max-width: 767px) {
  .last-box{
 
    height: 210px;
}
  .grid-enviamelo {
    background-size: auto;
    background-position: 0px 120px;
  }
  .app-container-enviamelo{
    padding-left: 8% !important;
    padding-right: 8% !important;
  }
  .paper-long img {
    max-width: 15% !important;
  }
  .MuiPaper-root img {
    max-width: 45%;
  }
  .MuiGrid-item img {
    max-width: 32%;
  }
  .no-paper {
    margin-top: 0 !important;
  }
  .app-lg-text {
    line-height: 15px;
  }
  .logistica-integral-title {
    top: 150%;
  }
  .grid-footer {
    margin-top: 20% !important;
  }
  .paper-middle img {
    max-width: 25% !important;
  }
  .registrate-title {
    line-height: 35px;
  }
}
/* 
  ## 320PX - 480PX
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 320PX to 480PX
  */
@media (min-width: 320px) and (max-width: 575px) {
  .logistica-integral-title {
    top: 120%;
  }
  .app-container-enviamelo{
    padding-left: unset;
    padding-right: 7% !important;
  }
  .grid-enviamelo {
    background-size: auto;
    background-position: 0px 450px;
  }
  .MuiPaper-root img {
    max-width: 70%;
  }
  .MuiGrid-item img {
    max-width: 45%;
  }
  .no-paper {
    margin-top: 0 !important;
  }
  .paper-long img {
    max-width: 25% !important;
  }
  .app-lg-text {
    line-height: 15px;
  }
  .grid-footer {
    margin-top: 20% !important;
  }
  .paper-middle img {
    max-width: 20% !important;
  }
  .registrate-title {
    line-height: 25px;
  }
  .MuiGrid-item {
    padding-left: 15px;
    padding-right: 15px;
  }
  .app-display-text{
    display: -webkit-inline-box;
  }
  .inline-p {
    display: inline-block;
  }
}
@media (min-width: 320px) and (max-width: 445px) {
  .app-display-text{
    display: -webkit-inline-box;
  }
  .MuiGrid-item img {
    max-width: 55%;
  }
  .inline-p {
    display: inline-block;
  }
  .MuiGrid-item {
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .grid-enviamelo {
    background-size: auto;
    background-position: center;
  }
  .app-container-enviamelo {
    padding-left: unset;
  }
}


.app-container-enviamelo .enacom-banner {
  margin-bottom: 0 !important;
  margin-top: 3%;
}

.app-container-enviamelo .enacom-banner img {
  max-width: unset;
  display: block;
  margin: 0 auto;
}