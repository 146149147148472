body,
html {
  height: 100%;
}

main {
  padding: 0 1em;
  overflow: hidden;
}
input,
select {
  border: 1px solid #10a3e6;
  padding-left: 10px !important;
  border-radius: 5px;
  font-size: 1.2em;
  height: 48px !important;
  width: 100%;
  color: #555;
  background: transparent;
  padding: 0;
}
.row {
     margin-right: 0px !important; 
     margin-left: 0px !important; 
}
.MuiGrid-spacing-xs-3 {
  width: 100% !important;
  margin: 0px !important;
}
.MuiContainer-root {
    padding-left: 5% !important; 
    padding-right: 5% !important; 
}
.enviamelo-mobile img{
  width: 100%;
}
.year-input {
  border: 1px solid #10a3e6 !important;
  border-radius: 5px !important;
  font-size: 1.2em !important;
  height: 48px !important;
  width: 100% !important;
  color: #555 !important;
  background: transparent !important;
  padding: 0 !important;
}

select {
  height: 50px !important;
}

.app-container {
  width: 100%;
}
.full-width {
	left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	position: relative;
	right: 50%;
	width: 100vw;
}
.app-what-is {
  margin-top: 2%;
}

.app-title {
/*   padding-left:10px !important; */
/*   border-left: solid 4px #0066ff; */
  font-size: 3em;
}

.app-what-is p {
/*   margin-left: 5%; */
}

.app-section {
  margin-top: 5%;
}

.enviamelo-mobile {
  position: absolute;
  bottom: -30%;
}
.enviamelo-mobile-2 {
  display: none;
}
.app-what-is img {
  padding-top: 2%;
  padding-left: 2%;
  max-width: 85%;
}

.app-lg-text {
  line-height: 25px;
  font-size: 20px;
}

.app-black-text-color {
  color: #555;
}
.app-orange-text-color {
  color: #F68D1E !important;
}
.app-gray-text-color{

  color: #A6A8AB;
}
.app-white-text-color{
  color: #ffffff;
}
.app-subtitle{
  margin-top: 10%;
}
.app-how-work-it,
.app-where-is {
  margin-top: 2%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.app-how-work-it img {
  width: 70px;
}

.app-how-work-it .paper-img {
  width: 50px;
}

.app-how-work-it .mobile-img {
  width: 44px;
}

.app-how-work-it div,
.app-where-is div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-how-work-it p {
  color: #555;
  width: 80%;
  font-size: 18px;
  text-align: center;
}

.app-how-work-it .right-arrow {
  width: 40px;
}

.app-where-is img {
  width: 90%;
}
.enviamelo-mobile-2 img {
  max-width: 65%;
}

.app-where-is p {
  color: #555;
  font-size: 20px;
  text-align: center;
}

.app-benefits .enviamelo-car-2 {
  margin-top: 20%;
  width: 100%;
}

.app-benefits .right .row {
  display: flex;
  align-items: center;
}

.app-benefits .right .row img {
  width: 45%;
}

.app-benefits .right .row p {
  font-size: 14px;
  color: #555;
}

.app-trademarks {
  display: flex;
  align-items: center;
}
.app-trademarks .col-md-4 {
  padding: 50px 100px;
}
.app-trademarks img {
  width: 100%;
}

.app-register .fa-star,
.app-register .fa-star-o {
  margin-right: 0.8em;
  font-size: 3.5em;
  color: #555 !important;
}

.app-register button {
  display: block;
  margin: 40px auto;
}

.srv-validation-message {
  color: #ff282d;
  font-size: 14px;
  padding-top: 5px;
}
.home .rodal-dialog {
  background: transparent !important;
  box-shadow: none !important;
}

.enterprises .rodal-dialog {
  overflow: auto;
  height: 70vh !important;
  width: 80vw !important;
  max-width: 480px;
}

.enterprises .rodal-dialog button {
  display: block;
  margin: 0 auto;
  width: 100%;
}

/**************************
 *  RESPONSIVE DESIGN     *
 *  Desktop First         *
 **************************/

/* 
  ## 1281PX and More
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
  */
  @media(min-width: 1750px){
    .app-what-is {
      margin-bottom: 80px;
    }
    .MuiContainer-maxWidthLg {
      max-width: 1750px;
    }
  }
@media(min-width: 1500px) and (max-width: 1749px){
  .app-what-is {
    margin-bottom: 80px;
  }
  .MuiContainer-maxWidthLg {
    max-width: 1749px;
  }
}
@media(min-width: 1281) and (max-width: 1499) {
 
  .MuiContainer-maxWidthLg {
    max-width: 1499px;
  }
  .app-what-is {
    margin-bottom: 80px;
  }
}

/* 
  ## 1025PX - 1280PX
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
  .app-title {
    font-size: 2em;
  }
  .app-lg-text {
    font-size: 16px;
  }
  .app-how-work-it p {
    font-size: 14px;
  }
  .app-where-is p {
    font-size: 16px;
  }
  .app-benefits .enviamelo-car-2 {
    margin-top: 20%;
    width: 90%;
  }
  .app-benefits h3 {
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  .app-benefits .right .row {
    margin: 0;
  }
  .app-trademarks .col-md-4 {
    padding: 25px 50px;
  }
  .app-register h2 {
    font-size: 14px;
  }
  .app-register .fa-star,
  .app-register .fa-star-o {
    font-size: 2em;
  }
  .app-how-work-it img {
    width: 40px;
  }
  .app-how-work-it .paper-img {
    width: 38px;
  }
  .app-how-work-it .mobile-img {
    width: 34px;
  }
  .app-how-work-it .right-arrow {
    width: 20px;
  }
}

/* 
  ## 768PX - 1024PX
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  .app-title {
    font-size: 2em;
  }
  .app-lg-text {
    font-size: 16px;
  }
  .app-how-work-it p {
    font-size: 14px;
  }
  .app-where-is p {
    font-size: 16px;
  }
  .app-benefits .enviamelo-car-2 {
    margin-top: 20%;
    width: 90%;
  }
  .app-benefits h3 {
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  .app-benefits .right .row {
    margin: 0;
  }
  .app-trademarks .col-md-4 {
    padding: 25px 50px;
  }
  .app-register h2 {
    font-size: 14px;
  }
  .app-register .fa-star,
  .app-register .fa-star-o {
    font-size: 2em;
  }
  .app-how-work-it img {
    width: 40px;
  }
  .app-how-work-it .paper-img {
    width: 38px;
  }
  .app-how-work-it .mobile-img {
    width: 34px;
  }
  .app-how-work-it .right-arrow {
    width: 20px;
  }
}

/* 
    ## 768PX - 1024PX
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
    */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .app-title {
    font-size: 1.5em;
  }
  .app-lg-text {
    font-size: 12px;
  }
  .app-how-work-it p {
    font-size: 12px;
  }
  .app-where-is p {
    font-size: 12px;
  }
  .app-benefits h3 {
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  .app-benefits .right .row p {
    font-size: 10px;
  }
  .app-benefits .right .row {
    margin: 0;
  }
  .app-trademarks .col-md-4 {
    padding: 25px 50px;
  }
  .app-register h2 {
    font-size: 14px;
  }
  .app-register .fa-star,
  .app-register .fa-star-o {
    font-size: 2em;
  }
}

/* 
    ## 481 - 767PX
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
    */
@media (min-width: 481px) and (max-width: 767px) {
  input,
  select {
    font-size: 14px;
  }
  .app-title {
    padding-left: 5px;
    font-size: 18px;
  }
  .app-lg-text {
    font-size: 12.5px;
  }
/*   .register-btn {
    display: none;
  } */
  .enviamelo-mobile {
    display: none;
  }
  .enviamelo-mobile-2 {
    display: block;
  }

  .app-how-work-it img {
    width: 48px;
  }

  .app-how-work-it .paper-img {
    width: 34px;
  }

  .app-how-work-it .mobile-img {
    width: 28px;
  }
  .app-how-work-it p {
    width: 100%;
    font-size: 10px;
  }

  .app-how-work-it .right-arrow {
    width: 14px;
  }
  .app-where-is p {
    font-size: 12px;
  }
  .app-benefits .enviamelo-car-2 {
    display: none;
  }
  .app-benefits .right .row img {
    width: 40px;
  }
  .app-benefits .right h3 {
    font-size: 14px;
  }
  .app-benefits .right .row p {
    font-size: 12px;
  }
  .app-trademarks .col-md-4 {
    padding: 10px 20px;
  }
  .app-register h2 {
    font-size: calc(6px + 1vw);
  }
  .app-register .fa-star,
  .app-register .fa-star-o {
    font-size: 1.5em;
  }
  .css-1wa3eu0-placeholder {
    font-size: calc(6px + 1vw);
  }
}
/* 
    ## 320PX - 480PX
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 320PX to 480PX
    */
@media (min-width: 320px) and (max-width: 480px) {
  input,
  select {
    font-size: 14px;
  }
  .app-title {
    padding-left: 5px;
    font-size: 16px;
  }
  .app-lg-text {
    font-size: 10.5px;
  }
/*   .register-btn {
    display: none;
  } */
  .enviamelo-mobile {
    display: none;
  }
  .enviamelo-mobile-2 {
    display: block;
  }

  .app-how-work-it img {
    width: 34px;
  }

  .app-how-work-it .paper-img {
    width: 30px;
  }

  .app-how-work-it .mobile-img {
    width: 24px;
  }
  .app-how-work-it p {
    width: 100%;
    font-size: 8px;
  }

  .app-how-work-it .right-arrow {
    width: 12px;
  }
  .app-where-is p {
    font-size: 10px;
  }
  .app-benefits .enviamelo-car-2 {
    display: none;
  }
  .app-benefits .right .row img {
    width: 40px;
  }
  .app-benefits .right h3 {
    font-size: 14px;
  }
  .app-benefits .right .row p {
    font-size: 12px;
  }
  .app-trademarks .col-md-4 {
    padding: 10px 20px;
  }
  .app-register h2 {
    font-size: calc(6px + 1vw);
  }
  .app-register .fa-star,
  .app-register .fa-star-o {
    font-size: 1.5em;
  }
  .css-1wa3eu0-placeholder {
    font-size: calc(6px + 1vw);
  }
  .navbar-brand{
    margin-left: 20px;
  }
}
