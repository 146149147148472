.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding-top: 1.5em;
}
.logo {
  max-width: 50%;
  min-width: 30%;
  
}

.register-nav{
  margin-top: -12px !important;
  color: white !important;
  border: 2px solid rgba(255, 255, 255) !important;
/*   height: 40px !important; */
/*   padding: 10px 15px !important; */
  padding-top: 15px;
  padding-bottom: 35px;
  min-width: 40px!important;
  min-height: auto!important;
  border-radius: 44px!important;
  font-weight: bold !important;
  font-size: 1.1rem!important;
}
.logo img{
  padding: 20px;


}
.list-item1 {
/*   margin-inline-end: 25px !important; */

}

.list-items {
  float: right;
  display: flex;
  flex-direction: row;
  list-style: none;
}
.navbar-menu{
  padding-top: 20px;
  font-weight: bold;
}
.list-items > li {
  margin-right: 5em;
}

.list-items a {
  font-size: 18px;
  color: white;
  text-decoration: none;
}

.blue-color a {
  color: #0066ff !important;
}

.list-items .selected {
  font-weight: bold;
}

.burger-menu,
.burger-menu-enterprise {
  display: none;
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 28px;
  height: 20px;
  right: 1em;
  top: 1em;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
  font-size: 24px;
  height: 12.3% !important;
}
/* Enterprises */
.burger-menu-enterprise .bm-burger-bars {
  background: #0066ff;
  font-size: 24px;
  height: 12.3% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #F68D1E;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  color: white;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 50px;
}
.selected {
  font-weight: bold !important;
}

.bm-item:focus {
  outline: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* 
## 768PX - 1024PX
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media(min-width: 1750px){
/*   .list-items a {
    font-size: 18px;
  }
  .list-items > li {
    margin-right: 1.8em;
} */

}
@media(min-width: 1500px) and (max-width: 1749px){
  .list-items a {
    font-size: 17px;
  }
  .list-items > li {
    margin-right: 1.6em;
}
}
@media(min-width: 1281px) and (max-width: 1499px) {
  .list-items a {
    font-size: 16px;
  }
  .list-items > li {
    margin-right: 1.4em;
}
}
@media (min-width: 1025px) and (max-width: 1280px) {
  .logo img{
    width: 220px;
  }
  .list-items {
    padding-inline-start: 4px !important;

  }
  .MuiButton-label {
    font-size: 0.9rem !important;
  }
  .list-items a {
    font-size: 13px;
  }
  .list-items > li {
    margin-right: 1.2em;
}
}
@media (min-width: 768px) and (max-width: 1024px) {
  .list-items {
    padding-inline-start: 8px !important;

  }
  .MuiButton-label {
    font-size: 0.8rem !important;
  }
  .logo img{
    width: 200px;
    padding-left: 10px !important;
  }
  .list-items a {
    font-size: 12px;
  }
  .list-items > li {
    margin-right: 1.0em;
}
}

/* 
    ## 768PX - 1024PX
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
    */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .list-items {
    padding-inline-start: 6px !important;

  }
  .logo img{
    width: 160px;
  }
  .MuiButton-label {
    font-size: 0.6rem !important;
  }
  .logo {
    width: 120px;
  }
  .list-items a {
    font-size: 11px;
  }
  .list-items > li {
    margin-right: 1em;
}
}

/* 
    ## 481 - 767PX
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
    */
@media (min-width: 576px) and (max-width: 767px) {
 
  .logo img{
    width: 140px;
  }
  .MuiButton-label {
    font-size: 0.7rem !important;
  }
  .logo{
    margin: -10px;
  }
  .logo img{
    padding: 0;
   /*  display: none; */
  }
  .burger-button {
    display: block;
  }
  .list-items {
    display: none;
  }
  .burger-menu,
  .burger-menu-enterprise {
    display: inherit;
  }
}

/* 
## 320PX - 480PX
##Device = Tablets, Ipads (landscape)
##Screen = B/w 320PX to 480PX
*/
@media (min-width: 320px) and (max-width: 575px) {
  .logo img{
    width: 180px;
  }
  .logo{
    margin: -10px;
  }
  .logo img{
    padding:0;
    
   /*  display: none; */
  }

  .burger-button {
    display: block;
  }
  .list-items {
    display: none;
  }
  .burger-menu,
  .burger-menu-enterprise {
    display: inherit;
  }


  
}
