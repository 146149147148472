
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  src: url('./resources/fonts/Roboto-Regular.ttf'); /* IE9 Compat Modes */
}

body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*  */
/* code {url(./../../resources/images/mask-header.svg), url(./../../resources/images/header.png); 
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
 */